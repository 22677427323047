import React, { useEffect, useState } from 'react';
import AddMetaTags from "components/add_meta_tags";
import LeftSidebar from "components/left_sidebar";
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchListImageAlbums, uploadImageToAlbums, deleteImageAlbums, createAnAlbum, moveImageToOtherAlbums } from 'actions/albums';
import ListAlbums from 'components/Albums/index';
import Spinner from 'react-bootstrap/Spinner';
import Dropdown from 'react-bootstrap/Dropdown';
import ModalConfirmDelete from 'components/shared/modalDeleteConfirmComment/modal';
import ModalAddAlbums from 'components/Albums/ModalAddAlbums';
import ModaMoveImage from './Components/ModalMoveImage';

function UserAlbums() {
  const dispatch = useDispatch();
  let [imageId, setImageId] = useState(null);
  let { albumId } = useParams();
  let [showModalAddAlbums, setShowModalAddAlbums] = useState(false);
  let [albumsName, setAlbumsName] = useState("");
  let [otherAlbumId, setOtherAlbumsId] = useState("");
  let [showModalAlbumsId, setShowModalAlbumsId] = useState("");

  const { data, isLoading } = useSelector(state => state.albums.listImageAlbums);
  const { data: albumsDetail } = useSelector(state => state.albums.listAlbumsDetail);
  useEffect(() => {
    dispatch(fetchListImageAlbums(albumId));
  }, [albumId, dispatch]);

  const handleUploadImage = (e) => {
    let formData = new FormData();
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      formData.append('attachment[file][]', files[i]);
    }
    if (files.length > 0) dispatch(uploadImageToAlbums(formData, albumId));
  }

  const handleDestroyImageInAlbums = (imageId) => {
    dispatch(deleteImageAlbums(albumId, imageId));
  }

  const handleAddAlbums = () => {
    dispatch(createAnAlbum({ name: albumsName }));
    setShowModalAddAlbums(false);
  }

  const handleMoveImageToOtherAlbums = () => {
    dispatch(moveImageToOtherAlbums(albumId, showModalAlbumsId, otherAlbumId));
    setShowModalAlbumsId("");
    setOtherAlbumsId("");
  }

  return (
    <div className='albums'>
      <AddMetaTags title={`Albums`} />
      <div className='row'>
        <LeftSidebar />
        <div className='col-md-9 col-sm-12'>
          <div className="font-weight-bold fs-3 primary-clr mb-2">Danh sách album</div>
          <ListAlbums />
          <div className="font-weight-bold fs-3 primary-clr mb-2">{albumsDetail?.name}</div>
          <div className='row albums-list'>
            {data.map(function (image, index) {
              return (
                <Col sm={4} xs={6} md={6} lg={3} className='col-12p5' key={index}>
                  <div className='item-lst'>
                    <div className="bg-img-orgnal rounded border-0 position-relative" style={{ background: `url(${image?.name})` }} />
                    <Dropdown className="position-absolute top-0 end-0 more-btn">
                      <Dropdown.Toggle variant="success" id="icon-dot">
                      </Dropdown.Toggle>
                      <Dropdown.Menu as='ul' style={{ zIndex: 1000000 }}>
                        <Dropdown.Item as='li' onClick={() => setShowModalAlbumsId(image.id)}>Chuyển albums</Dropdown.Item>
                        <ModaMoveImage isOpen={showModalAlbumsId} onToggle={() => setShowModalAlbumsId("")} onMove={() => handleMoveImageToOtherAlbums()} setOtherAlbumsId={setOtherAlbumsId} otherAlbumId={otherAlbumId} />
                        <Dropdown.Item as='li' onClick={() => setImageId(image.id)}>Xóa</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              )
            })}
            <ModalConfirmDelete isOpen={imageId} onDelete={() => handleDestroyImageInAlbums(imageId)} onToggle={() => setImageId(null)} />
            <Col sm={4} xs={6} md={6} lg={3} className='col-12p5 mb-3'>
              <div className="item-lst">
                <div className="bg-img-orgnal rounded bg-white border-0">
                  {isLoading ? <Spinner animation="border" variant="warning" style={{ position: 'absolute', top: '50%', left: '40%' }} /> :
                    <>  <span className="icon-close d-inline-block"></span>
                      <input type='file' className='form-control h-100 w-100' onChange={(e) => handleUploadImage(e)} multiple accept=".jpg, .jpeg, .png" /></>}
                </div>
              </div>
            </Col>
          </div>
          <ModalAddAlbums isOpen={showModalAddAlbums}
            onToggle={() => setShowModalAddAlbums(false)}
            onAdd={() => handleAddAlbums()}
            setAlbumsName={setAlbumsName}
            albumsName={albumsName}
          />
        </div>
      </div>
    </div>

  );
}

export default UserAlbums;

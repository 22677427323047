import React, { useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { toastError } from 'helpers/toastHelper';

function ModalAddAlbums({ isOpen, onToggle, onMove, otherAlbumId, setOtherAlbumsId }) {
  const { data: listAlbums } = useSelector((state) => state.albums.listAlbums);
  let { albumId } = useParams();

  useEffect(() => {
    if (listAlbums.length > 0) {
      setOtherAlbumsId(_.first(listAlbums.filter(it => it.id != albumId))?.id)
    }
  }, [listAlbums]);

  const handleMoveImageToOtherAlbums = () => {
    if (listAlbums.filter(it => it.id != albumId).length > 0) {
      onMove();
      onToggle();
    }
    else {
      toastError('không thể chuyển ảnh qua albums khác');
      return;
    }
  }
  return (
    <>
      <Modal show={isOpen} onHide={onToggle} aria-labelledby="contained-modal-title-vcenter" centered style={{ zIndex: 100000 }}>
        <Modal.Header closeButton>
          <Modal.Title>Chuyển ảnh qua albums khác</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <select className='form-control' onChange={(e) => setOtherAlbumsId(e.target.value)} >
            {listAlbums && listAlbums.filter(it => it.id != albumId).map(function (album, index) {
              return (
                <option value={album?.id} key={index}>{album?.name}</option>
              )
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-primary" onClick={() => handleMoveImageToOtherAlbums()}>
            gửi
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalAddAlbums;

import { React, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Gộp useLocation và useNavigate
import AddMetaTags from 'components/add_meta_tags';
import { toastError } from 'helpers/toastHelper';
import PlantDiagnosisResult from "./Components/PlantDiagnosisResult";
import PestDiagnosisResult from "./Components/PestDiagnosisResult";
import SameDiagnosisResult from "./Components/SameDiagnosisResult";
import AuthenPopup from "./Components/AuthenPopup";
import "./DiagnosticResult.scss";
const DiagnosticResultOA = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location || {};
  const result = state?.result;

  const [showModal, setShowModal] = useState(true);

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (!result) {
      toastError("Không có dữ liệu chẩn đoán. Vui lòng thử lại.");
      navigate("/modules/nhan-dang-sau-benh-oa");
      return null;
    }
  }, [result, navigate]);

  return (
    <>
      <AddMetaTags title={"Kết quả chẩn đoán"} />
      <div className="tts">
        <div className="row flex-center">
          <div className="col-md-9 col-sm-12">
            {result.data.category
              ? result.data.summaries.length === 0
                ? <PlantDiagnosisResult data={result.data} />
                : <PestDiagnosisResult data={result.data} />
              : <SameDiagnosisResult data={result.data} />}
          </div>
        </div>
        <AuthenPopup
          show={showModal}
          handleClose={handleCloseModal}
          type={"zalo-oa"}
        />
      </div>
    </>
  );
};

export default DiagnosticResultOA;

import React, { useEffect, useState, useRef } from "react";
import { getCataloguesShortVideos } from "apis/catalogue";
import moment from "moment";
import "moment/locale/vi";
import VideoOfCatalogues from "./videoOfCatalogues";
moment.locale("vi");

function Videos(props) {
  const isMounted = useRef(true);
  let { setSearch, keyword } = props;
  let [catalogue, setCatalogue] = useState([]);
  let [activeLinkTagvideo, setActiveLinkTagVideo] = useState(null);

  useEffect(() => {
    getCataloguesShortVideos().then((res) => {
      if (isMounted.current) {
        let result = res["data"]["data"];
        if (result) {
          setCatalogue(result);
        }
      }
    });
    return () => {
      setSearch("");
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (!keyword) {
        setActiveLinkTagVideo(null);
      }
    }
  }, [keyword]);

  return (
    <div className="col-md-6 col-sm-12">
      <div className="cont-center">
        <div className="mt-1">
          <p className="fs-3 d-flex justify-content-center text-uppercase fw-bold mb-0">
            Video ngắn
          </p>
          {catalogue &&
            catalogue.map(function (group, index) {
              return (
                <div className="mt-3 video-sectn" key={index}>
                  <h5 className="title-line-lt text-uppercase fw-bold mb-0">
                    <span className="d-block">{group.name}</span>
                  </h5>
                  <div className="row">
                    <VideoOfCatalogues id={group.id} />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
export default Videos;
